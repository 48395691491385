#app {
	display: -moz-flex;
	display: -webkit-flex;
	display: -ms-flex;
	display: flex;
	-moz-flex-direction: column;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-moz-justify-content: center;
	-webkit-justify-content: center;
	-ms-justify-content: center;
	justify-content: center;
	cursor: default;
	min-height: 100vh;
	overflow: hidden;
	position: relative;
	text-align: center;
	background: #eeeeee;
}

#app .liwetripapp {
    min-height: 100vh;
    margin-top: 20px;
    margin-bottom: 20px;
	background-image: url("images/app.png");
	background-position: center;
	background-repeat: no-repeat;
}

#app .liwetripinfo {
    min-height: 100vh;
    background-color: #cccccc;
}

#app .appstore:after {
	background-image: url("images/appstore.png");
	background-position: center;
	background-repeat: no-repeat;
	background-size: contain;
    height: 67px;
    width: 200px;
	bottom: 2em;
	margin-bottom: 2em;
	content: '';
	display: block;
}

ul.large {
    list-style: disc;
 	padding-left: 1em;
 	margin: 0 0 0 0;
}

ul.sublarge {
    list-style: circle;
 	padding-left: 3em;
 	padding-bottom: 0em;
 	margin: 0 0 0 0;
}

ul.large li {
    text-align: left;
    color: black;
    padding-left: 0.5em;
    padding-bottom: 1.0em;
}

@media screen and (max-width: 1680px) {
    ul.large {
 	    margin: 3em 2em 2em 3em;
    }

    ul.large li {
        font-size: 1.4em;
        line-height: 1.3;
    }
}

@media screen and (max-width: 736px) {
    #app .liwetripinfo {
        background-color: #cccccc;
    }

    ul.large {
 	    margin: 2em 2em 2em 3em;
    }

    ul.large li {
        font-size: 1.0em;
        line-height: 1.3;
    }
}

