@import url("fonts/font-awesome.min.css");
@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,400italic,600,600italic,800,800italic");

html, body, div, span, applet, object,
iframe, h1, h2, h3, h4, h5, h6, p, blockquote,
pre, a, abbr, acronym, address, big, cite,
code, del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var, b,
u, i, center, dl, dt, dd, ol, ul, li, fieldset,
form, label, legend, table, caption, tbody,
tfoot, thead, tr, th, td, article, aside,
canvas, details, embed, figure, figcaption,
footer, header, hgroup, menu, nav, output, ruby,
section, summary, time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
	display: block;
}

body {
	line-height: 1;
}

body {
	-webkit-text-size-adjust: none;
}

input::-moz-focus-inner {
	border: 0;
	padding: 0;
}

input, select, textarea {
	-moz-appearance: none;
	-webkit-appearance: none;
	-ms-appearance: none;
	appearance: none;
}

ol, ul {
	list-style: none;
}

/* Basic */

@-ms-viewport {
	width: device-width;
}

html {
	box-sizing: border-box;
}

*, *:before, *:after {
	box-sizing: inherit;
}

body {
	background: #2e3842;
}

body.is-preload *, body.is-preload *:before, body.is-preload *:after {
	-moz-animation: none !important;
	-webkit-animation: none !important;
	-ms-animation: none !important;
	animation: none !important;
	-moz-transition: none !important;
	-webkit-transition: none !important;
	-ms-transition: none !important;
	transition: none !important;
}

body, input, select, textarea {
	color: #fff;
	font-family: "Open Sans", Helvetica, sans-serif;
	font-size: 15pt;
	font-weight: 400;
	letter-spacing: 0.075em;
	line-height: 1.65em;
}

@media screen and (max-width: 1680px) {
        body, input, select, textarea {
	    font-size: 13pt;
	}
}

@media screen and (max-width: 1280px) {
        body, input, select, textarea {
		    font-size: 12pt;
	}

}

p {
	margin: 0 0 2em 0;
}

h1, h2, h3, h4, h5, h6 {
	color: #fff;
	font-weight: 800;
	letter-spacing: 0.225em;
	line-height: 1em;
	margin: 0 0 1em 0;
	text-transform: uppercase;
}

h1 a, h2 a, h3 a, h4 a, h5 a, h6 a {
	color: inherit;
	text-decoration: none;
}

@media screen and (max-width: 1680px) {
    h4 {
	    font-size: 1em;
	    line-height: 1.5em;
    }
}

@media screen and (max-width: 980px) {
    h1, h4 {
	    font-size: 0.75em;
	    line-height: 1.5em;
    }
}

/* Row */

.row {
	display: flex;
	flex-wrap: wrap;
	box-sizing: border-box;
	align-items: stretch;
}

.row.gtr-uniform > * > :last-child {
    margin-bottom: 0;
}

.row.aln-center {
	justify-content: center;
}

.row > .col-1 {
	width: 8.3333333333%;
}

.row > .col-6 {
	width: 50%;
}

@media screen and (max-width: 1680px) {
    .row {
	    margin-top: 0;
		margin-left: -1.5em;
	}

    .row > * {
        padding: 0 0 0 1.5em;
	}

    .row.gtr-uniform {
        margin-top: -1.5em;
	}

    .row.gtr-uniform > * {
        padding-top: 1.5em;
	}

	.row > .col-4 {
        width: 33.3333333333%;
    }
}

@media screen and (max-width: 736px) {
    .row {
	    margin-top: 0;
		margin-left: -1.5em;
	}

    .row > * {
        padding: 0 0 0 1.5em;
	}

    .row.gtr-uniform {
        margin-top: -1.5em;
	}

    .row.gtr-uniform > * {
        padding-top: 1.5em;
	}

    .row > .col-4 {
        width: 90%;
    }

    .row > .col-6 {
       	width: 100%;
    }
}

/* Form */

form {
    margin: 0 0 2em 0;
}

label {
	color: #fff;
	display: block;
	font-size: 0.9em;
	font-weight: 600;
	margin: 0 0 1em 0;
}

input[type="text"],
input[type="password"],
input[type="email"],
select,
textarea {
	-moz-appearance: none;
	-webkit-appearance: none;
	-ms-appearance: none;
	appearance: none;
	background-color: rgba(144, 144, 144, 0.25);
	border-radius: 3px;
	border: none;
	color: inherit;
	display: block;
	outline: 0;
	padding: 0 1em;
	text-decoration: none;
	width: 100%;
}

input[type="text"]:invalid,
input[type="password"]:invalid,
input[type="email"]:invalid,
select:invalid,
textarea:invalid {
    box-shadow: none;
}

input[type="text"]:focus,
input[type="password"]:focus,
input[type="email"]:focus,
select:focus,
textarea:focus {
	box-shadow: 0 0 0 4px rgba(34, 167, 240);
}

input[type="text"],
input[type="password"],
input[type="email"],
select {
	height: 2.75em;
}

::-webkit-input-placeholder {
	color: rgba(255, 255, 255, 0.5) !important;
	opacity: 1.0;
}

:-moz-placeholder {
	color: rgba(255, 255, 255, 0.5) !important;
	opacity: 1.0;
}

::-moz-placeholder {
	color: rgba(255, 255, 255, 0.5) !important;
	opacity: 1.0;
}

:-ms-input-placeholder {
	color: rgba(255, 255, 255, 0.5) !important;
	opacity: 1.0;
}

/* Icon */

.icon {
    text-decoration: none;
    border-bottom: none;
    position: relative;
}

.icon:before {
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	font-family: FontAwesome;
	font-style: normal;
	font-weight: normal;
	text-transform: none !important;
}

.icon > .label {
	display: none;
}

/* List */

ol li {
	padding-left: 0.25em;
}

ul {
    list-style: disc;
	margin: 0 0 2em 0;
	padding-left: 1em;
}

ul li {
    padding-left: 0.5em;
}

ul.alt {
    list-style: none;
    padding-left: 0;
}

ul.alt li {
	border-top: solid 1px #fff;
	padding: 0.5em 0;
}

ul.alt li:first-child {
    border-top: 0;
	padding-top: 0;
}

/* Icons */

ul.icons {
    cursor: default;
	list-style: none;
	padding-left: 0;
}

ul.icons li {
    display: inline-block;
    padding: 0 1em 0 0;
}

ul.icons li:last-child {
    padding-right: 0 !important;
}

/* Button */

input[type="submit"],
input[type="reset"],
input[type="button"],
button,
.button {
	-moz-appearance: none;
	-webkit-appearance: none;
	-ms-appearance: none;
	appearance: none;
	-moz-transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
	-webkit-transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
	-ms-transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
	transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
	background-color: transparent;
	border-radius: 3px;
	border: 0;
	box-shadow: inset 0 0 0 2px #fff;
	color: #fff;
	cursor: pointer;
	display: inline-block;
	font-size: 0.8em;
	font-weight: 600;
	height: 3.125em;
	letter-spacing: 0.225em;
	line-height: 3.125em;
	max-width: 30em;
	padding: 0 2.75em;
	text-align: center;
	text-decoration: none;
	text-transform: uppercase;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

input[type="submit"]:hover,
input[type="reset"]:hover,
input[type="button"]:hover,
button:hover,
.button:hover {
	background-color: rgba(144, 144, 144, 0.25);
}

input[type="submit"]:active,
input[type="reset"]:active,
input[type="button"]:active,
button:active,
.button:active {
	background-color: rgba(144, 144, 144, 0.5);
}

input[type="submit"].icon:before,
input[type="reset"].icon:before,
input[type="button"].icon:before,
button.icon:before,
.button.icon:before {
	margin-right: 0.5em;
}

input[type="submit"].fit,
input[type="reset"].fit,
input[type="button"].fit,
button.fit,
.button.fit {
	width: 100%;
}

input[type="submit"].arrow,
input[type="reset"].arrow,
input[type="button"].arrow,
button.arrow,
.button.arrow {
    background-image: url("images/arrow.png");
    background-repeat: no-repeat;
    background-position: center;
    box-shadow: none !important;
    color: #ffffff !important;
}

input[type="checkbox"],
input[type="radio"] {
	-moz-appearance: none;
	-webkit-appearance: none;
	-ms-appearance: none;
	appearance: none;
	display: block;
	float: left;
	margin-right: -2em;
	opacity: 0;
	width: 1em;
	z-index: -1;
}

input[type="checkbox"] + label,
input[type="radio"] + label {
	text-decoration: none;
	color: #fff;
	cursor: pointer;
	display: inline-block;
	font-size: 1.0em;
	font-weight: 400;
	padding-left: 2.4em;
	padding-right: 0.75em;
	position: relative;
}

input[type="checkbox"] + label:before,
input[type="radio"] + label:before {
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	font-family: FontAwesome;
	font-style: normal;
	font-weight: normal;
	text-transform: none !important;
}

input[type="checkbox"] + label:before,
input[type="radio"] + label:before {
	background: rgba(144, 144, 144, 0.25);
	border-radius: 3px;
	content: '';
	display: inline-block;
	height: 1.65em;
	left: 0;
	line-height: 1.58125em;
	position: absolute;
	text-align: center;
	top: 0;
	width: 1.65em;
}

input[type="checkbox"]:checked + label:before,
input[type="radio"]:checked + label:before {
	background: #2e3842;
	color: #fff;
	content: '\f00c';
}

input[type="checkbox"]:focus + label:before,
input[type="radio"]:focus + label:before {
	box-shadow: 0 0 0 3px #18a8ed;
}

input[type="checkbox"] + label:before {
	border-radius: 3px;
}

input[type="radio"] + label:before {
	border-radius: 100%;
}

::-webkit-input-placeholder {
	color: rgba(255, 255, 255, 0.5) !important;
	opacity: 1.0;
}

:-moz-placeholder {
	color: rgba(255, 255, 255, 0.5) !important;
	opacity: 1.0;
}

::-moz-placeholder {
	color: rgba(255, 255, 255, 0.5) !important;
	opacity: 1.0;
}

:-ms-input-placeholder {
	color: rgba(255, 255, 255, 0.5) !important;
	opacity: 1.0;
}

/* Wrapper */

.wrapper {
	padding: 6em 0 4em 0 ;
}

.wrapper.style5 {
	background-color: #ffffff;
	color: #4E4852;
}

.wrapper.style5 h2, .wrapper.style5 h3, .wrapper.style5 h4, .wrapper.style5 h5, .wrapper.style5 h6 {
    color: #2E3842;
}

.wrapper.style5 label {
    color: #2E3842;
}

/* Page Wrapper + Menu */

#page-wrapper {
	-moz-transition: opacity 0.5s ease;
	-webkit-transition: opacity 0.5s ease;
	-ms-transition: opacity 0.5s ease;
	transition: opacity 0.5s ease;
	opacity: 1;
	padding-top: 3em;
}

#page-wrapper:before {
	background: rgba(0, 0, 0, 0);
	content: '';
	display: block;
	display: none;
	height: 100%;
	left: 0;
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 10001;
}

/* Header */

#header {
	-moz-transition: background-color 0.2s ease;
	-webkit-transition: background-color 0.2s ease;
	-ms-transition: background-color 0.2s ease;
	transition: background-color 0.2s ease;
	background: #2e3842;
	height: 3em;
	left: 0;
	line-height: 3em;
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 10000;
}

#header h1 {
    -moz-transition: opacity 0.2s ease;
    -webkit-transition: opacity 0.2s ease;
    -ms-transition: opacity 0.2s ease;
    transition: opacity 0.2s ease;
    height: inherit;
    left: 1.25em;
	line-height: inherit;
	position: absolute;
	top: 0;
}

#header h1 a {
	border: 0;
	display: block;
	height: inherit;
	line-height: inherit;
}

#header.alt {
    background-color: #19b0da;
    background-image: linear-gradient(#1aaed9, #0c7ab4);
}

#header.alt h1 {
    pointer-events: none;
	opacity: 1;
}

/* Footer */

#footer {
    padding: 6em 0 4em 0 ;
    background-color: #1d242a;
    text-align: center;
}

#footer .icons {
	font-size: 1.25em;
}

#footer .icons a {
    color: rgba(255, 255, 255, 0.5);
}

#footer .icons a:hover {
    color: #fff;
}

#footer .copyright {
	color: rgba(255, 255, 255, 0.5);
	font-size: 0.8em;
	letter-spacing: 0.225em;
	list-style: none;
	padding: 0;
	text-transform: uppercase;
}

#footer .copyright li {
	border-left: solid 1px rgba(255, 255, 255, 0.5);
	display: inline-block;
	line-height: 1em;
	margin-left: 1em;
	padding-left: 1em;
}

#footer .copyright li:first-child {
	border-left: 0;
	margin-left: 0;
	padding-left: 0;
}

#footer .copyright li a {
    color: inherit;
}

#footer .copyright li a:hover {
    color: #fff;
}

@media screen and (max-width: 980px) {
    #footer {
        padding: 4em 3em 2em 3em ;
	}
}

/* Landing */

body.landing #page-wrapper {
	background-image: -moz-linear-gradient(top, rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url("images/banner.jpg");
	background-image: -webkit-linear-gradient(top, rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url("images/banner.jpg");
	background-image: -ms-linear-gradient(top, rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url("images/banner.jpg");
	background-image: linear-gradient(top, rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url("images/banner.jpg");
	background-attachment: fixed;
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
	padding-top: 0;
}

body.landing #footer {
	background-color: rgba(29, 36, 42, 0.9);
}
