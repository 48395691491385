.board {
	display: -moz-flex;
	display: -webkit-flex;
	display: -ms-flex;
	display: flex;
	-moz-flex-direction: column;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-moz-justify-content: center;
	-webkit-justify-content: center;
	-ms-justify-content: center;
	justify-content: center;
	cursor: default;
	min-height: 100vh;
	overflow: hidden;
	position: relative;
	text-align: center;
	background: #f7f7ff;
	color: #000000;
}

.ReactTable {
    position: relative;
    display: flex;
    flex-direction: column;
    margin-left: 2%;
    margin-right: 2%;
    font-size: 12pt;
}

.ReactTable * {
    box-sizing: border-box;
}

.ReactTable .rt-table {
    flex: auto 1;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    border-collapse: collapse;
    border-spacing: 0;
    overflow: auto;
}

.ReactTable .rt-thead {
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    border-bottom: solid 2px #000;
}

.ReactTable .rt-thead .rt-tr {
	border-left: 0;
	border-right: 0;
    text-align: center;
}

.ReactTable .rt-tr {
    flex: 1 0 auto;
    display: inline-flex;
}

.ReactTable .-odd {
    background-color: rgba(192, 192, 192, 0.25);
}

.ReactTable .rt-thead .rt-th, .ReactTable .rt-thead .rt-td {
    padding: 5px 5px;
    line-height: normal;
    position: relative;
    transition: box-shadow .3s cubic-bezier(.175,.885,.32,1.275);
    box-shadow: inset 0 0 0 0 transparent;
}

.ReactTable .rt-thead .rt-th {
	font-weight: 600;
	padding: 0 0.75em 0.75em 0.75em;
	text-align: left;
}

.ReactTable .rt-th, .ReactTable .rt-td {
    flex: 1 0;
    text-align: left;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding: 7px 5px;
    overflow: hidden;
    transition: .3s ease;
    transition-property: width,min-width,padding,opacity;
}

.ReactTable .rt-th-center, .ReactTable .rt-td-center {
    text-align: center;
}

.ReactTable .rt-tbody {
    flex: 99999 1 auto;
    display: flex;
    flex-direction: column;
    overflow: auto;
}

.ReactTable .rt-tbody .rt-tr {
    border-bottom: solid 1px #000;
}

.ReactTable .rt-tbody .rt-td {
    padding: 0.75em 0.75em;
    vertical-align: bottom;
}

.ReactTable .rt-tr-group {
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
    align-items: stretch;
}

.on-time {
    background-color: #7ed321;
    border-radius: 4px;
    padding: 2px;
}

.obsolete-time {
    font-size: 1.0em;
    display: block;
    text-decoration: line-through;
}

.before-time {
    background-color: #7ed321;
    border-radius: 4px;
    padding: 2px;
}

.minor-delay-time {
    background-color: #f9bf3b;
    border-radius: 4px;
    padding: 2px;
}

.major-delay-time {
    background-color: #ed4933;
    border-radius: 4px;
    padding: 2px;
}

.canceled-time {
    background-color: #ed4933;
    border-radius: 4px;
    padding: 2px;
}
