#search {
	display: -moz-flex;
	display: -webkit-flex;
	display: -ms-flex;
	display: flex;
	-moz-flex-direction: column;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-moz-justify-content: center;
	-webkit-justify-content: center;
	-ms-justify-content: center;
	justify-content: center;
	cursor: default;
	height: 100vh;
	min-height: 35em;
	overflow: hidden;
	position: relative;
	text-align: center;
	background: #0B78B3;
}

.autocomplete-menu {
  position: absolute;
  box-sizing: border-box;
  padding-top: 4px;
  padding-left: -8px;
  z-index: 2;
}

@media screen and (max-width: 1680px) {
    .autocomplete-menu {
        width: 33.3333333333%;
    }
}

@media screen and (max-width: 736px) {
    .autocomplete-menu {
        width: 90%;
    }
}

.autocomplete-item {
  background-color: #4095bf;
  padding: 4px 6px;
  cursor: default;
  text-align: left;
}

.autocomplete-item-highlighted {
  background-color: #999999;
  padding: 2px 6px;
  color: white;
  text-align: left;
}

.autocomplete-item-header {
  background-color: #eeeeee;
  color: #454545;
  font-weight: bold;
}

#search .more {
	-moz-transition: -moz-transform 0.75s ease, opacity 0.75s ease;
	-webkit-transition: -webkit-transform 0.75s ease, opacity 0.75s ease;
	-ms-transition: -ms-transform 0.75s ease, opacity 0.75s ease;
	transition: transform 0.75s ease, opacity 0.75s ease;
	-moz-transition-delay: 3.5s;
	-webkit-transition-delay: 3.5s;
	-ms-transition-delay: 3.5s;
	transition-delay: 3.5s;
	-moz-transform: translateY(0);
	-webkit-transform: translateY(0);
	-ms-transform: translateY(0);
	transform: translateY(0);
	border: none;
	bottom: 0;
	color: #dddddd;
	font-size: 1.2em;
	font-weight: bold;
	height: 5.5em;
	left: 50%;
	letter-spacing: 0.225em;
	margin-left: -8.5em;
	opacity: 1;
	outline: 0;
	padding-left: 0.225em;
	position: absolute;
	text-align: center;
	text-transform: uppercase;
	width: 16em;
	z-index: 0;
}

#search .more:after {
	background-image: url("images/arrow.svg");
	background-position: center;
	background-repeat: no-repeat;
	background-size: contain;
	bottom: 2em;
	content: '';
	display: block;
	height: 1.5em;
	left: 50%;
	margin: 0 0 0 -0.75em;
	position: absolute;
	width: 1.5em;
}
