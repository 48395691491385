.privacy {
	display: -moz-flex;
	display: -webkit-flex;
	display: -ms-flex;
	display: flex;
	-moz-flex-direction: column;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-moz-justify-content: center;
	-webkit-justify-content: center;
	-ms-justify-content: center;
	justify-content: center;
	cursor: default;
	min-height: 100vh;
	overflow: hidden;
	position: relative;
	text-align: left;
	background: #f7f7ff;
	color: #000000;
}

.wrapper > .inner {
    width: 80%;
    margin: 0 auto;
}
